import {
  createListenerMiddleware,
  type TypedStartListening,
} from '@reduxjs/toolkit';
import _ from 'lodash';

import type { RootState } from 'features/app/store/store';
import { audioFlowSelector } from 'features/call/vrs-call/store/vrsAudioSelectors';
import { sendAudioFlowAnalytics } from 'features/analytics/store';

const listenerMiddleware = createListenerMiddleware();

type AppStartListening = TypedStartListening<RootState>;

(listenerMiddleware.startListening as AppStartListening)({
  predicate: (_action, currentState, previousState) => {
    const currentAudioFlow = audioFlowSelector(currentState);
    const prevAudioFlow = audioFlowSelector(previousState);
    return !_.isEqual(currentAudioFlow, prevAudioFlow);
  },
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    const currentAudioFlow = audioFlowSelector(state);

    await listenerApi.dispatch(
      sendAudioFlowAnalytics({
        toDirectLine: currentAudioFlow.toDirectLine,
        toVoiceLine: currentAudioFlow.toVoiceLine,
        audible: currentAudioFlow.audible,
      })
    );
  },
});

export const audioFlowMiddleware = listenerMiddleware.middleware;
