export const getBrowserInfo = (): { browser: string; version: string } => {
  const ua = navigator.userAgent;
  const match = (regex: RegExp) => regex.exec(ua)?.[1] || 'Unknown';

  if (ua.includes('Edg/')) {
    return { browser: 'Edge', version: match(/Edg\/([\d.]+)/) };
  }
  if (ua.includes('Chrome/')) {
    return { browser: 'Chrome', version: match(/Chrome\/([\d.]+)/) };
  }
  if (ua.includes('Firefox/')) {
    return { browser: 'Firefox', version: match(/Firefox\/([\d.]+)/) };
  }
  return { browser: 'Unknown', version: 'Unknown' };
};
