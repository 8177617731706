import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { ButtonProps } from 'features/ui/elements';
import { Button } from 'features/ui/elements';
import styled from 'features/theme/styled';
import { CallOutIncomingPhoneIcon } from 'assets/svg';
import theme from 'features/theme/theme';

interface CallDetailsButtonDeafAddTwoLineVcoItemProps
  extends Pick<ButtonProps, 'onClick' | 'disabled' | 'loading' | 'hotkey'> {
  className?: string;
}

export const CallDetailsButtonDeafAddTwoLineVcoItem: React.FC<
  CallDetailsButtonDeafAddTwoLineVcoItemProps
> = ({ onClick, disabled, loading, hotkey, className }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'vco2Line' });
  return (
    <RootContainer>
      <StyledButton
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        hotkey={hotkey}
        size='small'
        className={className}
        startIcon={<CallOutIncomingPhoneIconStyled />}
        aria-label='call-details-deaf-three-menu-add-vco'
      >
        {t('addNewLine')}
      </StyledButton>
    </RootContainer>
  );
};

const StyledButton = styled(Button)`
  && {
    margin-top: 8px;
    padding: 12px 12px;
    background-color: ${theme.palette.grey[900]};
    font-weight: normal;
    color: ${theme.palette.common.white};
    font-size: 16px;
    border: 1px solid ${theme.palette.grey[800]};
    border-radius: 6px;
    gap: 8px;

    &:hover {
      background-color: ${theme.palette.grey[800]};
    }
    .MuiButton-startIcon {
      margin-right: 4px;
    }
  }
`;

const CallOutIncomingPhoneIconStyled = styled(CallOutIncomingPhoneIcon)`
  width: 18px;
  height: 18px;
`;

const RootContainer = styled(Box)`
  position: absolute;
  right: 0;
`;
