export const maskValidate = [
  { mask: '###', definitions: { '#': /^[a-zA-Z0-9]+$/ } },
  { mask: '+###', definitions: { '#': /^[a-zA-Z0-9]+$/ } },
  { mask: '+1 (###) ###-####', definitions: { '#': /^[a-zA-Z0-9]+$/ } },
  { mask: '+# (###) ###-####', definitions: { '#': /^[a-zA-Z0-9]+$/ } },
  { mask: '+## (###) ###-####', definitions: { '#': /^[a-zA-Z0-9]+$/ } },
  { mask: '+### (###) ###-####', definitions: { '#': /^[a-zA-Z0-9]+$/ } },
  { mask: '+### ## ### ####-####', definitions: { '#': /^[a-zA-Z0-9]+$/ } },
  { mask: '+### ### ### ### #####', definitions: { '#': /^[a-zA-Z0-9]+$/ } },
  {
    mask: '+### ### ### ### ### #####',
    definitions: { '#': /^[a-zA-Z0-9]+$/ },
  },
];
