import { createAsyncThunk } from '@reduxjs/toolkit';
import type { EndEvent, RTCSession } from 'jssip/lib/RTCSession';

import { LogLevel } from 'features/analytics/enums';
import { sendAnalyticsInfo } from 'features/analytics/helpers';
import { forceCancelColdHandoff } from 'features/cold-handoff/store';
import { completeTeaming } from 'features/teaming/teaming-base/store';
import { Originator, RtcSessionTerminateCauses } from 'features/sip/enums';
import { getConnectionChangeTypeByOriginator } from 'features/sip/utils';
import { areSameEndpoint } from 'features/sip/helpers';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import { VriChatEventBus } from 'features/vri-chat/services';

const hasActiveConnection = (session: RTCSession | null): boolean => {
  const state = session?.connection?.connectionState;
  return state !== 'failed' && state !== 'disconnected' && state !== 'closed';
};

export const handleRtcSessionEndedThunk = createAsyncThunk(
  'sip/handleRtcSessionEnded',
  async (
    {
      event,
      currentSession,
    }: { event: EndEvent; currentSession: RTCSession | null },
    thunkAPI
  ) => {
    const isRefer = event.cause === RtcSessionTerminateCauses.REFER;
    if (event.originator === Originator.REMOTE) {
      const remoteUri = event.message?.from?.toString();
      const currentUri = currentSession?.remote_identity?.uri?.toString();

      const isSameEndpoint = areSameEndpoint(remoteUri, currentUri);
      const hasActiveConn = hasActiveConnection(currentSession);

      // IF the session is different or the connection is active,
      // This is to prevent the UI from updating when the call is already disconnected
      // OR errant BYE from different session or source
      if (!isSameEndpoint || hasActiveConn) {
        thunkAPI.dispatch(
          sendAnalyticsInfo({
            Method: 'handleRtcSessionEndedThunk',
            Level: LogLevel.INFO,
            Message: 'Ignoring BYE from different session',
            RemoteUri: remoteUri,
            CurrentSessionUri: currentUri,
            IsSameEndpoint: isSameEndpoint,
            HasActiveConnection: hasActiveConn,
            ConnectionState: currentSession?.connection?.connectionState,
          })
        );
        return;
      }
    }

    const isLeaveTeaming =
      event.cause === RtcSessionTerminateCauses.LEAVE_TEAMING;
    const isLeaveHandoff = event.cause === RtcSessionTerminateCauses.HANDOFF;
    const isAdminCanceledCall =
      event.cause === RtcSessionTerminateCauses.ADMIN_CANCELED_CALL;
    const isReturnToQueue =
      event.cause === RtcSessionTerminateCauses.RETURN_TO_QUEUE;
    const isSpecialEndEvent =
      isLeaveTeaming ||
      isLeaveHandoff ||
      isAdminCanceledCall ||
      isReturnToQueue ||
      isRefer;

    const message = event.message?.toString() ?? '';

    thunkAPI.dispatch(
      sendAnalyticsInfo({
        Method: 'handleRtcSessionEnded',
        Level: LogLevel.INFO,
        Cause: event?.cause,
        Event: { ...event, message },
      })
    );

    if (!isLeaveTeaming && !isRefer) {
      await thunkAPI.dispatch(completeTeaming()).unwrap();
    }

    ParticipantsStatusEventBus.deaf.$disconnected.next({
      connectionChangeType: getConnectionChangeTypeByOriginator(
        event.originator
      ),
      isConnectionCallStateNeeded: !isSpecialEndEvent,
    });

    if (!isLeaveHandoff && !isRefer) {
      thunkAPI.dispatch(forceCancelColdHandoff());
      VriChatEventBus.$leaveChat.next();
    }
  }
);
