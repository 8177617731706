import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { Call } from 'features/call/call-base/interfaces';
import { adaptIncomingCallDeaf } from 'features/call/call-deaf/helpers';
import type {
  CallDeafState,
  DeafParticipantStatus,
} from 'features/call/call-deaf/interfaces';

export const initialCallDeafState: CallDeafState = {
  name: '',
  phoneNumber: '',
  location: '',
  status: 'disconnected',
  isLoading: false,
  isMuted: false,
  connectionAttempts: 0,
  isSorenson: false,
};

export const callDeafSlice = createSlice({
  name: 'call-deaf',
  initialState: initialCallDeafState,
  reducers: {
    setReceivedDeaf: (state, action: PayloadAction<Call>) => {
      return {
        ...state,
        ...adaptIncomingCallDeaf(action.payload),
      };
    },
    setDeafStatus: (state, action: PayloadAction<DeafParticipantStatus>) => {
      const isConnecting = action.payload === 'connecting';
      if (isConnecting) {
        state.connectionAttempts += 1;
      }
      state.status = action.payload;
    },
    setDeafPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setDeafLocation: (state, action: PayloadAction<string>) => {
      state.location = action.payload;
    },
    setDeafIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    muteDeafAudio: (state) => {
      state.isMuted = true;
    },
    unmuteDeafAudio: (state) => {
      state.isMuted = false;
    },
    resetDeafAudio: (state) => {
      state.isMuted = false;
    },
    deafPartyResolved: (
      state,
      action: PayloadAction<{ name: string; isSorenson: boolean }>
    ) => {
      state.name = action.payload.name;
      state.isSorenson = action.payload.isSorenson;
    },
    resetCallDeaf: () => initialCallDeafState,
  },
});

export const {
  setReceivedDeaf,
  setDeafStatus,
  setDeafPhoneNumber,
  setDeafLocation,
  setDeafIsLoading,
  muteDeafAudio,
  unmuteDeafAudio,
  resetDeafAudio,
  resetCallDeaf,
  deafPartyResolved,
} = callDeafSlice.actions;

export const callDeafReducer = callDeafSlice.reducer;
