import { useCallback, useEffect, useState } from 'react';

import { callIdSelector } from 'features/call/call-base/store/selectors';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { updateCalleePhoneNumber } from 'features/call/call-base/store';
import { setPrimaryHearingPhoneNumber } from 'features/call/call-hearing/store';
import {
  addCalledConnectionPoints,
  emergencyConnectionPointSelector,
  emergencyPhoneNumberSelector,
  isAddressVerificationNeededSelector,
  setIsEmergencyDeafReconnectionNeeded,
  turnOffSpawningEmergencyCall,
} from 'features/emergency/store';
import { isAcceptingUserSelector } from 'features/teaming/teaming-base/store';
import { useHearingConnect } from 'features/voice-meeting/hooks';
import { consoleLogDev } from 'common/helpers/consoleLogDev';
import { selectIsEmergencyHearingNotDisconnected } from 'features/emergency/store/emergencyHearingSelectors';

export const useEmergencyAutoConnect = () => {
  const dispatch = useAppDispatch();
  const { connectHearing } = useHearingConnect();
  const emergencyPhoneNumber = useAppSelector(emergencyPhoneNumberSelector);
  const isAddressVerificationNeeded = useAppSelector(
    isAddressVerificationNeededSelector
  );
  const emergencyConnectionPoint = useAppSelector(
    emergencyConnectionPointSelector
  );
  const callId = useAppSelector(callIdSelector);
  const isAcceptingUser = useAppSelector(isAcceptingUserSelector);
  const isEmergencyHearingNotDisconnected = useAppSelector(
    selectIsEmergencyHearingNotDisconnected
  );
  const [isAutoConnectAttempted, setIsAutoConnectAttempted] = useState(false);

  const handleAutoConnectFinishOrCancel = useCallback(() => {
    dispatch(turnOffSpawningEmergencyCall());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const attemptAutoConnect = useCallback(async () => {
    try {
      await dispatch(updateCalleePhoneNumber()).unwrap();
      dispatch(setPrimaryHearingPhoneNumber(emergencyPhoneNumber));
      await connectHearing(emergencyPhoneNumber);
      if (emergencyConnectionPoint) {
        dispatch(addCalledConnectionPoints(emergencyConnectionPoint));
      }
      dispatch(setIsEmergencyDeafReconnectionNeeded(false));
    } finally {
      handleAutoConnectFinishOrCancel();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * INFO: Adding `connectHearing`, which is not wrapped with `useCallback`, may lead to infinite calls of this function.
     */
  }, [emergencyConnectionPoint, emergencyPhoneNumber]);

  useEffect(() => {
    setIsAutoConnectAttempted(false);
  }, [emergencyConnectionPoint]);

  useEffect(() => {
    if (
      !callId ||
      isAddressVerificationNeeded ||
      isAcceptingUser ||
      emergencyConnectionPoint === 'OTHER'
    ) {
      logDev('Skipping, not allowed');
      handleAutoConnectFinishOrCancel();
      return;
    }

    if (isAutoConnectAttempted) {
      logDev('Skipping, already attempted');
      return;
    }

    if (!emergencyPhoneNumber) {
      return;
    }

    if (isEmergencyHearingNotDisconnected) {
      setIsAutoConnectAttempted(true);
      logDev('Skipping, already not disconnected');
      return;
    }

    logDev('Attempting to connect');
    setIsAutoConnectAttempted(true);
    attemptAutoConnect();
  }, [
    isAutoConnectAttempted,
    isEmergencyHearingNotDisconnected,
    callId,
    isAddressVerificationNeeded,
    isAcceptingUser,
    attemptAutoConnect,
    handleAutoConnectFinishOrCancel,
    emergencyConnectionPoint,
    emergencyPhoneNumber,
  ]);

  const logDev = (message: string) => {
    consoleLogDev(`DEBUG: Emergency auto connect. ${message}.`);
  };
};
