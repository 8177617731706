import { createSelector } from '@reduxjs/toolkit';

import { selectIsAppConfigRetrievedSuccessful } from 'features/app-config/store';
import { isRnsConnectionSuccessSelector } from 'features/app/store/app/selectors';
import type { RootState } from 'features/app/store/store';
import { selectIsNoLanguageSelected } from 'features/queue-settings/store';
import {
  isVpnDetectedSelector,
  bypassVpnRestrictionsSelector,
} from 'features/vpn-detector/store';

export const rnsConnectionIdSelector = ({ session }: RootState) =>
  session.rnsConnectionId;

export const sessionIdSelector = ({ session }: RootState) => session.id;

export const isSessionStartAvailableSelector = createSelector(
  selectIsAppConfigRetrievedSuccessful,
  selectIsNoLanguageSelected,
  isRnsConnectionSuccessSelector,
  isVpnDetectedSelector,
  bypassVpnRestrictionsSelector,
  (
    isAppConfigRetrievedSuccessful,
    isNoLanguageSelected,
    isRnsConnectionSuccess,
    isVpnDetected,
    bypassVpnRestrictions
  ) => {
    return (
      isAppConfigRetrievedSuccessful &&
      !isNoLanguageSelected &&
      isRnsConnectionSuccess &&
      (bypassVpnRestrictions || !isVpnDetected)
    );
  }
);
