import { ConnectionChangeType } from 'features/call/call-base/enums';
import { Originator } from 'features/sip/enums';

export const getConnectionChangeTypeByOriginator = (originator: Originator) => {
  if (originator === Originator.LOCAL) {
    return ConnectionChangeType.AGENT_DISCONNECT_DEAF;
  }

  if (originator === Originator.REMOTE) {
    return ConnectionChangeType.DEAF_DISCONNECT;
  }

  return ConnectionChangeType.UNKNOWN;
};
