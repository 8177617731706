import { Subject } from 'rxjs';

interface SendDtmfEvent {
  digit: string;
  shouldSendDtmfThroughShareText: boolean;
}

export class DialPadEventBus {
  static readonly sendDtmf$ = new Subject<SendDtmfEvent>();
  static readonly clearDtmf$ = new Subject<void>();
}
