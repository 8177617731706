import packageJson from '../../../../package.json';

import { ProductSipHeaderTag } from 'features/product/enums';
import { sorensonSipVersion } from 'features/sip/constants';

export class ProductSipHeader {
  private static _productName: string = packageJson.name;
  private static _version: string = packageJson.version;

  static updateProductInfo(productName: string, version: string) {
    this._productName = productName;
    this._version = version;
  }

  static get version(): string {
    return this._version;
  }

  static get sipHeader(): Record<ProductSipHeaderTag, string> {
    return {
      [ProductSipHeaderTag.NAME]: this._productName,
      [ProductSipHeaderTag.VERSION]: this.version,
      [ProductSipHeaderTag.SORENSON_SIP_VERSION]: sorensonSipVersion,
    };
  }
}
