import type { RootState } from 'features/app/store/store';
import { AudioSource as Source } from 'features/call/call-base/enums';
import { isMicrophoneMutedSelector } from 'features/call/call-base/store';
import {
  isDeafConnectedSelector,
  isDeafMutedSelector,
} from 'features/call/call-deaf/store';
import { selectIsAnyHearingConnectedOrConnecting } from 'features/call/call-hearing/store';
import { isRemoteTeamingStartedSelector } from 'features/teaming/teaming-base/store';
import {
  isPrimaryVcoSenderSelector,
  isVcoRequestedSelector,
} from 'features/vco/store';

// Lesson learned: selectors need to be in a separate file from their slice.
// Want to know why? Me, too.

/** Returns the audio sources that should be sent to the hearing line. */
export const sourcesForHearingLineSelector = (state: RootState) => {
  const audio = state.audio;

  if (!audio.isAudioReady) {
    return [];
  }

  const sources = [];

  const is1LineVco = isVcoRequestedSelector(state);
  const isTeaming = isRemoteTeamingStartedSelector(state);

  const isHearingConnectedOrConnecting =
    selectIsAnyHearingConnectedOrConnecting(state);
  const isAnyOtherHearing = isTeaming || isHearingConnectedOrConnecting;
  const isPrimaryVcoSender = isPrimaryVcoSenderSelector(state);

  const isDeafConnected = isDeafConnectedSelector(state);
  const isDeafMuted = isDeafMutedSelector(state);
  const isMuted = isMicrophoneMutedSelector(state);

  if (isAnyOtherHearing && !isMuted) {
    sources.push(Source.InputDevice);
  }

  if (
    isAnyOtherHearing &&
    is1LineVco &&
    isPrimaryVcoSender &&
    isDeafConnected &&
    !isDeafMuted
  ) {
    sources.push(Source.DirectLine);
  }

  return sources;
};

/** Returns the audio sources that should be sent to the direct line. */
export const sourcesForDirectLineSelector = (state: RootState) => {
  const audio = state.audio;

  if (!audio.isAudioReady) {
    return [];
  }

  const sources = [];
  const is1LineVco = isVcoRequestedSelector(state);

  const isHearingConnectedOrConnecting =
    selectIsAnyHearingConnectedOrConnecting(state);
  const isTeamConnected = isRemoteTeamingStartedSelector(state);
  const isPrimaryVcoSender = isPrimaryVcoSenderSelector(state);

  const isMuted = isMicrophoneMutedSelector(state);

  if (!isMuted && is1LineVco && isPrimaryVcoSender) {
    sources.push(Source.InputDevice);
  }

  if (
    isPrimaryVcoSender &&
    is1LineVco &&
    (isHearingConnectedOrConnecting || isTeamConnected)
  ) {
    sources.push(Source.VoiceLine);
  }

  return sources;
};

/** Returns the audio sources that should be audible (played on the speaker/headset). */
export const audibleSourcesSelector = (state: RootState) => {
  if (!state.audio.isAudioReady) {
    return [];
  }

  const sources = [];

  const is1LineVco = isVcoRequestedSelector(state);
  const isPrimaryVcoSender = isPrimaryVcoSenderSelector(state);
  const isDeafMuted = isDeafMutedSelector(state);

  if (is1LineVco && isPrimaryVcoSender && !isDeafMuted) {
    sources.push(Source.DirectLine);
  }

  const isTeaming = isRemoteTeamingStartedSelector(state);
  const isHearingConnected = selectIsAnyHearingConnectedOrConnecting(state);

  if (isTeaming || isHearingConnected) {
    sources.push(Source.VoiceLine);
  }

  return sources;
};

export const audioFlowSelector = (state: RootState) => {
  return {
    toDirectLine: sourcesForDirectLineSelector(state),
    toVoiceLine: sourcesForHearingLineSelector(state),
    audible: audibleSourcesSelector(state),
  };
};

export const isAudioReadySelector = (state: RootState) => {
  return state.audio.isAudioReady;
};
