import type { AppConfig } from 'features/app-config/interfaces';
import { AppConfigService } from 'features/app-config/services';
import {
  fetchAppConfig,
  appConfigRetrievedSuccessful,
} from 'features/app-config/store';
import { useAppDispatch } from 'features/app/hooks';
import { useEmergencyFlag } from 'features/emergency/hooks';
import { setEmergencyPhoneNumbers } from 'features/emergency/store';
import { handleError } from 'features/notification/store';
import {
  sendAnalyticsError,
  sendAnalyticsInfo,
} from 'features/analytics/helpers';

export const useFetchAppConfig = () => {
  const dispatch = useAppDispatch();
  const { isEmergencyEnabled } = useEmergencyFlag();
  const MAX_RETRIES = 3;
  const BASE_DELAY_MS = 1000;

  // Try to fetch and process config with retries
  const fetchConfig = async (): Promise<AppConfig> => {
    for (let attempt = 0; attempt <= MAX_RETRIES; attempt++) {
      try {
        // Fetch config
        const config = await dispatch(fetchAppConfig()).unwrap();

        // Handle emergency settings if needed
        if (isEmergencyEnabled) {
          if (config.EmergencyExt && config.Intrado911ConnectionString) {
            dispatch(setEmergencyPhoneNumbers(config));
          } else {
            throw new Error('Missing emergency numbers');
          }
        }

        if (config.ActiveLanguages.length === 0) {
          throw new Error('No active languages');
        }

        // Save config and log success
        AppConfigService.setAppConfig(config);
        dispatch(appConfigRetrievedSuccessful());
        dispatch(
          sendAnalyticsInfo({ message: 'Config loaded successfully', config })
        );

        return config;
      } catch (error) {
        // Handle failure on last attempt
        if (attempt === MAX_RETRIES) {
          dispatch(
            handleError({
              error,
              methodName: 'fetchConfig',
              message: 'Failed to load config',
            })
          );
          throw error;
        }

        // Log retry and wait
        const delayMs = BASE_DELAY_MS * Math.pow(2, attempt);
        dispatch(
          sendAnalyticsError({
            message: `Config fetch attempt ${attempt + 1}/${MAX_RETRIES + 1} failed. Retrying in ${delayMs}ms`,
            error: error instanceof Error ? error : new Error('Unknown error'),
            retryCount: attempt + 1,
            delayMs,
          })
        );
        await new Promise((resolve) => setTimeout(resolve, delayMs));
      }
    }

    throw new Error('All config fetch attempts failed');
  };

  return { fetchConfig };
};
