import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { ConferenceType } from 'features/call/call-conference/enums';

export interface ConferenceState {
  type: ConferenceType;
  url: string;
  isJoinedToZoom: boolean;
  hasConnected: boolean;
  didAgentLeaveConference: boolean;
}

export const initialConferenceState: ConferenceState = {
  type: ConferenceType.NONE,
  url: '',
  isJoinedToZoom: false,
  hasConnected: false,
  didAgentLeaveConference: false,
};

export const conferenceSlice = createSlice({
  name: 'conference',
  initialState: initialConferenceState,
  reducers: {
    setConferenceType: (state, action: PayloadAction<ConferenceType>) => {
      state.type = action.payload;
    },
    setConferenceUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    setJoinedToZoom: (state) => {
      state.isJoinedToZoom = true;
    },
    conferenceConnectionsReported: (state) => {
      state.hasConnected = true;
    },
    agentLeftConference: (state) => {
      state.didAgentLeaveConference = true;
    },
    resetConference: () => initialConferenceState,
  },
});

export const {
  setConferenceType,
  setConferenceUrl,
  setJoinedToZoom,
  conferenceConnectionsReported,
  resetConference,
  agentLeftConference,
} = conferenceSlice.actions;

export const conferenceReducer = conferenceSlice.reducer;
