import { Typography } from '@mui/material';
import type { FC } from 'react';

import theme from 'features/theme/theme';
import { useProductInfo } from 'features/product/hooks';

export const AppVersion: FC = () => {
  const { version } = useProductInfo();

  return (
    <>
      {version && (
        <Typography
          variant='body1'
          color={theme.palette.common.white}
          p={theme.spacing(7, 0, 0, 2)}
        >
          v.{version}
        </Typography>
      )}
    </>
  );
};
