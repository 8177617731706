import type { Action, ThunkAction } from '@reduxjs/toolkit';

import {
  isHearingToDeafSelector,
  callIdSelector,
  peerUserAgentSelector,
  sipCallIdSelector,
} from 'features/call/call-base/store/selectors';
import { normalizeAnalyticsKeys } from 'features/analytics/helpers/';
import type { SendAnalyticsRequest } from 'features/analytics/interfaces';
import { AnalyticsDaoService } from 'features/analytics/services';
import type { RootState } from 'features/app/store/store';
import { LoggerService } from 'features/logger/services';
import { ProductSipHeader } from 'features/product/services';
import {
  agentNumberSelector,
  callCenterCodeSelector,
} from 'features/user/store';
import { voiceSessionIdSelector } from 'features/voice-session/store';
import { selectCallPageStatus } from 'features/call/call-ui-state/store';

// This signature includes some `any` types,
// which do in theory reduce the effectiveness of the type checker.
// We may be able to improve this after migrating to Redux Toolkit 2.0.
// Relevant reading: https://redux.js.org/usage/usage-with-typescript#type-checking-redux-thunks
type Signature = (
  payload: SendAnalyticsRequest
) => ThunkAction<Promise<void>, any, any, Action>;

export const sendAnalytics: Signature = (payload) => {
  return async (_dispatch: unknown, getState: () => RootState) => {
    const state = getState();
    const callPageStatus = selectCallPageStatus(state);
    const callId = callIdSelector(state);
    const agentNumber = agentNumberSelector(state);
    const callCenterCode = callCenterCodeSelector(state);
    const sipCallId = sipCallIdSelector(state);
    const voiceSessionId = voiceSessionIdSelector(state);
    const userAgent = peerUserAgentSelector(state);
    const checkedKeys = normalizeAnalyticsKeys(payload);
    const isHearingToDeaf = isHearingToDeafSelector(state);
    const webRtcConnections = state.callerSession.webRtcRemoteIdentities;
    const switchboardConnections = state.callerSession.switchboardConnections;
    const callerSessionStatus = state.callerSession.status;

    try {
      const event = {
        ...checkedKeys,
        CallPageStatus: callPageStatus,
        CallId: callId,
        AgentNumber: agentNumber,
        CallCenterCode: callCenterCode,
        SessionLoginHistoryId: state.session.id,
        MercuryVersion: ProductSipHeader.version ?? undefined,
        SipCallId: sipCallId ?? '',
        RnsConnectionId: state.session.rnsConnectionId ?? '',
        VoiceSessionId: voiceSessionId ?? '',
        Mode: state.multiMode.mode,
        EndpointType: userAgent ?? '',
        CallType: isHearingToDeaf ? 'H2D' : 'D2H',
        CallerSessionStatus: callerSessionStatus ?? '',
        WebRtcConnections: webRtcConnections,
        SwitchboardConnections: switchboardConnections,
      };
      await AnalyticsDaoService.sendAnalytics(event);
    } catch (error) {
      LoggerService.error(error);
    }
  };
};
