/**
 * Extracts the base SIP URI from a string
 */
export const extractSipUri = (str?: string | null): string | null => {
  if (!str) {
    return str === '' ? '' : null;
  }

  const match = str.match(/(?:<)?(sip:[^>;\s]+)(?:>)?/i);
  return match ? match[1].toLowerCase() : str;
};

/**
 * Normalizes a SIP URI for comparison
 */
export const normalizeSipUri = (uri?: string | null): string | null => {
  if (!uri) {
    return null;
  }

  // Extract the base URI
  const baseUri = extractSipUri(uri);
  if (baseUri === null) {
    return null;
  }

  // Extract user part and domain from SIP URI
  const uriMatch = baseUri.match(/^sip:([^@]+)@(.+)$/i);
  if (!uriMatch) {
    return baseUri;
  }

  const userPart = uriMatch[1];
  const domain = uriMatch[2];

  // Strip any + prefix for normalization
  const rawNumber = userPart.replace(/^\+/, '');

  // For US/Canada numbers: if it starts with 1 and has 11 digits, remove the leading 1
  // This standardizes +12025551234 and +2025551234 to the same value
  if (rawNumber.startsWith('1') && rawNumber.length === 11) {
    return `sip:${rawNumber.substring(1)}@${domain}`;
  }
  return `sip:${rawNumber}@${domain}`;
};

/**
 * Checks if two SIP URIs refer to the same endpoint
 */
export const areSameEndpoint = (
  uri1?: string | null,
  uri2?: string | null
): boolean => {
  // If they are the same, return true early
  if (uri1 === uri2) {
    return true;
  }

  // If either is null/undefined, return false
  if (!uri1 || !uri2) {
    return false;
  }

  // Process both URIs for comparison
  const normalizedUri1 = normalizeSipUri(uri1);
  const normalizedUri2 = normalizeSipUri(uri2);

  // Both URIs must be valid after processing
  if (normalizedUri1 === null || normalizedUri2 === null) {
    return false;
  }

  // Compare the processed URIs
  return normalizedUri1 === normalizedUri2;
};
