import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { AppConfig } from 'features/app-config/interfaces';
import type { Call } from 'features/call/call-base/interfaces';
import {
  EmergencyCallDetailsStatus,
  EmergencyCallType,
  EmergencyConnectionPoint,
} from 'features/emergency/enums';
import { getEmergencyPhoneNumbers } from 'features/emergency/helpers';
import type {
  EmergencyCoordinates,
  EmergencyState,
  RegisteredAddress,
} from 'features/emergency/interfaces';
import { env } from 'features/environment/constants';

export const initialEmergencyState: EmergencyState = {
  isSpawningCall: false,
  callDetailsStatus: EmergencyCallDetailsStatus.CALLEE_DISCONNECTED,
  isAddressVerificationNeeded: false,
  isEmergencyOccurredAtRegisteredLocation: true,
  phoneNumbers: {
    PSAP: env.emergency.fakePSAP,
    ECRC: env.emergency.fakeECRC,
  },
  registeredAddress: {
    name: '',
    status: 'pending',
  },
  isEmergencyDialback: false,
  isGeoCoordinateAvailable: false,
  verify911Address: false,
  isDeafReconnectionNeeded: false,
  isAbandonedEmergencyCall: false,
};

const emergencySlice = createSlice({
  name: 'emergency',
  initialState: initialEmergencyState,
  reducers: {
    turnOnSpawningEmergencyCall: (state) => {
      state.isSpawningCall = true;
    },
    turnOffSpawningEmergencyCall: (state) => {
      state.isSpawningCall = false;
    },
    setEmergencyAddressInformation: (state, action: PayloadAction<Call>) => {
      return {
        ...state,
        isGeoCoordinateAvailable: action.payload.IsGeoCoordinateAvailable,
        verify911Address: action.payload.Verify911Address,
      };
    },
    setEmergencyCallDetailsStatus: (
      state,
      action: PayloadAction<EmergencyCallDetailsStatus>
    ) => {
      state.callDetailsStatus = action.payload;
    },
    markAsEmergencyCall: (state) => {
      state.callType = EmergencyCallType.EMERGENCY;
    },
    markAsEmergencyCallback: (state) => {
      state.callType = EmergencyCallType.EMERGENCY_CALLBACK;
    },
    setEmergencyPhoneNumbers: (state, action: PayloadAction<AppConfig>) => {
      state.phoneNumbers = getEmergencyPhoneNumbers(action.payload);
    },
    setEmergencyConnectionPoint: (
      state,
      action: PayloadAction<EmergencyConnectionPoint>
    ) => {
      state.connectionPoint = action.payload;
    },
    addCalledConnectionPoints: (
      state,
      action: PayloadAction<EmergencyConnectionPoint>
    ) => {
      if (!state.calledConnectionPoints) {
        state.calledConnectionPoints = [action.payload];
      } else if (!state.calledConnectionPoints.includes(action.payload)) {
        state.calledConnectionPoints.push(action.payload);
      }
    },
    setIsEmergencyOccurredInRegisteredLocation: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isEmergencyOccurredAtRegisteredLocation = action.payload;
      state.isAddressVerificationNeeded = false;
    },
    setRegisteredAddress: (state, action: PayloadAction<RegisteredAddress>) => {
      state.registeredAddress = action.payload;
    },
    setEmergencyCoordinates: (
      state,
      action: PayloadAction<EmergencyCoordinates>
    ) => {
      state.coordinates = action.payload;
    },
    setIsEmergencyDialback: (state, action: PayloadAction<boolean>) => {
      state.isEmergencyDialback = action.payload;
    },
    markAddressVerificationNeeded: (state) => {
      state.isAddressVerificationNeeded = true;
    },
    markAddressVerified: (state) => {
      state.isAddressVerificationNeeded = false;
    },
    setEmergencyCallbackConnectionPoint: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.connectionPoint = action.payload
        ? EmergencyConnectionPoint.PSAP
        : EmergencyConnectionPoint.ECRC;
    },
    setIsEmergencyDeafReconnectionNeeded: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeafReconnectionNeeded = action.payload;
    },
    resetEmergency: (state) => {
      // phone numbers should not be reset they are the same for all calls
      return { ...initialEmergencyState, phoneNumbers: state.phoneNumbers };
    },
    markAsAbandonedEmergencyCall: (state) => {
      state.isAbandonedEmergencyCall = true;
    },
  },
});

export const {
  setEmergencyAddressInformation,
  setEmergencyCallDetailsStatus,
  setEmergencyConnectionPoint,
  addCalledConnectionPoints,
  setIsEmergencyDialback,
  setEmergencyCoordinates,
  setIsEmergencyOccurredInRegisteredLocation,
  setRegisteredAddress,
  markAddressVerificationNeeded,
  markAsEmergencyCall,
  markAsEmergencyCallback,
  resetEmergency,
  setEmergencyPhoneNumbers,
  setEmergencyCallbackConnectionPoint,
  markAddressVerified,
  setIsEmergencyDeafReconnectionNeeded,
  turnOnSpawningEmergencyCall,
  turnOffSpawningEmergencyCall,
  markAsAbandonedEmergencyCall,
} = emergencySlice.actions;

export const emergencyReducer = emergencySlice.reducer;
