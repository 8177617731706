import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { ConnectionChangeType } from 'features/call/call-base/enums';
import { setDeafIsLoading, setDeafStatus } from 'features/call/call-deaf/store';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import {
  SignMailRecordingStatus,
  SignMailStatus,
} from 'features/signmail/enums';
import { SignMailEventBus } from 'features/signmail/services';
import {
  changeSignMailStatus,
  selectSignMailRecordingStatus,
  startSignMailCountdown,
  startSignmailSession,
  shouldStartNewSignMailSession,
  signMailSessionEnded,
} from 'features/signmail/store';
import { sendAnalyticsInfo } from 'features/analytics/helpers';
import { useCallDeafParticipantHandlers } from 'features/call/call-accepted/hooks';
import { callIdSelector } from 'features/call/call-base/store/selectors';

export const useSignMailHandlers = () => {
  const dispatch = useAppDispatch();
  const signMailRecordingStatus = useAppSelector(selectSignMailRecordingStatus);
  const callId = useAppSelector(callIdSelector);
  const { handleDeafCancel } = useCallDeafParticipantHandlers();

  const handleEndSignMailSession = useCallback(() => {
    dispatch(setDeafIsLoading(true));
    if (signMailRecordingStatus === SignMailRecordingStatus.RECORDING) {
      SignMailEventBus.recordingFinished$.next();
    } else {
      ParticipantsStatusEventBus.deaf.$disconnected.next({
        connectionChangeType: ConnectionChangeType.AGENT_DISCONNECT_DEAF,
      });
    }
    ParticipantsStatusEventBus.deaf.$disconnectSignMail.next();
    dispatch(signMailSessionEnded());
  }, [dispatch, signMailRecordingStatus]);

  const handleCountdownEnded = () => {
    dispatch(changeSignMailStatus(SignMailStatus.RECORDING));
  };

  const handleSkipToSignMail = useCallback(async () => {
    handleDeafCancel();
    dispatch(setDeafStatus('unavailable'));
    const shouldStartSignMail = shouldStartNewSignMailSession();
    if (!shouldStartSignMail) {
      dispatch(
        sendAnalyticsInfo({
          method: 'handleSkipToSignMail',
          message:
            'Cant start new signmail session. Two signmail sessions cannot be in progress at the same time.',
          LogGroup: 'SignMail',
        })
      );
      return;
    }
    ParticipantsStatusEventBus.deaf.$connectSignMail.next();
    dispatch(startSignmailSession({ callId }));
    dispatch(changeSignMailStatus(SignMailStatus.COUNTDOWN));
    dispatch(
      sendAnalyticsInfo({
        method: 'handleSkipToSignMail',
        message:
          'Agent skipped to signmail using skip button. Signmail session started.',
        LogGroup: 'SignMail',
      })
    );
  }, [callId, dispatch, handleDeafCancel]);
  const handleGreetingEnded = useCallback(() => {
    dispatch(startSignMailCountdown());
  }, [dispatch]);

  return {
    handleEndSignMailSession,
    handleGreetingEnded,
    handleCountdownEnded,
    handleSkipToSignMail,
  };
};
