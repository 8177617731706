import { getBrowserInfo } from 'common/utils';
import { sendAnalyticsInfo } from 'features/analytics/helpers';
import { SessionLogMessages } from 'features/session/enums';

export const startTakingCallsAnalytics = (methodName: string) => {
  const { browser, version } = getBrowserInfo();
  return sendAnalyticsInfo({
    Method: methodName,
    Message: SessionLogMessages.START_TAKING_CALLS_CLICK,
    Browser: browser,
    BrowserVersion: version,
  });
};

export const finishTakingCallsAnalytics = (
  methodName: string,
  logoutType: string
) => {
  return sendAnalyticsInfo({
    Method: methodName,
    LogoutType: logoutType,
    Message: SessionLogMessages.FINISH_TAKING_CALLS_SUCCESS,
  });
};
