import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import {
  addPlusToPhoneNumber,
  parsePhoneNumber,
} from 'features/call/call-base/helpers';
import { hearingAudioReady } from 'features/call/call-base/store';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import { deafPhoneNumberByModeSelector } from 'features/call/call-deaf/store';
import { AddHearingStatus } from 'features/call/call-details/enums';
import { setAddHearingStatus } from 'features/call/call-details/store';
import { useHearings } from 'features/call/call-hearing/hooks';
import { HearingParticipantType } from 'features/call/call-hearing/interfaces';
import {
  addHearing,
  callErrorSelector,
  clearCallError,
  primaryHearingPhoneNumberSelector,
  setCallError,
  setHearingLoadingStateByPhoneNumber,
  setPrimaryHearingPhoneNumber,
} from 'features/call/call-hearing/store';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import { callerSessionAnalyticsError } from 'features/caller-session/helpers';
import { checkShouldAllowNewHearingConnection } from 'features/caller-session/store';
import { isVrsModeSelector } from 'features/multi-mode/store';
import {
  resolveN11PhoneNumber,
  isSpecialPhoneNumber,
} from 'features/n11/helpers';
import { handleError } from 'features/notification/store';
import { isTeamingInProgressSelector } from 'features/teaming/teaming-base/store';
import { agentNumberSelector, userNameSelector } from 'features/user/store';
import {
  useVoiceMeetingSessionConnect,
  useVoiceMeetingSetupListeners,
} from 'features/voice-meeting/hooks';
import { VoiceMeetingService } from 'features/voice-meeting/services';
import { VoiceSessionStatus } from 'features/voice-session/enums';
import {
  resetVoiceSession,
  setVoiceSession,
  setVoiceSessionLoading,
  setVoiceSessionStatus,
} from 'features/voice-session/store';

export const useVoiceMeetingSessionInit = () => {
  const dispatch = useAppDispatch();
  const primaryHearingPhoneNumber = useAppSelector(
    primaryHearingPhoneNumberSelector
  );
  const deafPhoneNumberByMode = useAppSelector(deafPhoneNumberByModeSelector);
  const agentNumber = useAppSelector(agentNumberSelector);
  const agentUserName = useAppSelector(userNameSelector);
  const callId = useAppSelector(callIdSelector);
  const isTeaming = useAppSelector(isTeamingInProgressSelector);

  const { startNewSession, connectUserToExistingSession } =
    useVoiceMeetingSessionConnect();
  const { setupListeners } = useVoiceMeetingSetupListeners();
  useHearings();
  const isVrs = useAppSelector(isVrsModeSelector);
  const { t } = useTranslation('translation', {
    keyPrefix: 'callErrors',
  });
  const callError = useAppSelector(callErrorSelector);

  const replaceN11Number = async (
    n11PhoneNumber: string,
    isSecondary = false
  ) => {
    try {
      const { phoneNumber, n11Status } = await resolveN11PhoneNumber(
        n11PhoneNumber,
        callId
      );

      if (!phoneNumber || n11Status === 1) {
        dispatch(setCallError(t('unableConnectNumber')));
        return n11PhoneNumber;
      }

      if (!isSecondary) {
        dispatch(setPrimaryHearingPhoneNumber(phoneNumber));
      } else {
        dispatch(
          addHearing({
            id: '',
            phoneNumber: addPlusToPhoneNumber(parsePhoneNumber(phoneNumber)),
            name: phoneNumber,
            location: '',
            phoneExtension: '',
            status: 'disconnected',
            type: HearingParticipantType.SECONDARY,
            isLoading: false,
          })
        );
      }
      return phoneNumber;
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'replaceN11Number',
        })
      );
    }
  };

  const clearCallErrorIfExists = () => {
    if (callError) {
      dispatch(clearCallError());
    }
  };

  const createNewVoiceSession = async (phoneNumber: string) => {
    let hearingPhoneNumber = phoneNumber;
    try {
      dispatch(setVoiceSessionLoading(true));
      if (isVrs && isSpecialPhoneNumber(phoneNumber)) {
        const replacedNumber = await replaceN11Number(phoneNumber);
        hearingPhoneNumber = replacedNumber ?? phoneNumber;
        if (phoneNumber === hearingPhoneNumber) {
          return;
        }
      }
      clearCallErrorIfExists();
      dispatch(setVoiceSessionStatus(VoiceSessionStatus.CONNECTING));
      const { shouldAllow, reasonMessage } = dispatch(
        checkShouldAllowNewHearingConnection(phoneNumber)
      );
      if (!shouldAllow) {
        dispatch(setAddHearingStatus(AddHearingStatus.ENTER_PHONE_NUMBER));
        dispatch(callerSessionAnalyticsError(reasonMessage));
        setHearingLoadingStateByPhoneNumber({ isLoading: false, phoneNumber });
        return;
      }

      ParticipantsStatusEventBus.hearing.$connecting.next(hearingPhoneNumber);

      await startNewSession({
        agentUserName,
        callId,
        deafPhoneNumber: deafPhoneNumberByMode,
        hearingPhoneNumber: addPlusToPhoneNumber(
          parsePhoneNumber(hearingPhoneNumber)
        ),
      });
    } catch (error: any) {
      dispatch(resetVoiceSession());
      ParticipantsStatusEventBus.hearing.$disconnected.next({
        phoneNumber: hearingPhoneNumber,
      });
      if (error?.message?.includes('Invalid dialString')) {
        dispatch(
          handleError({
            error,
            methodName: 'createNewVoiceSession',
            title: t('invalidDialString'),
          })
        );
      } else {
        dispatch(
          handleError({
            error,
            methodName: 'createNewVoiceSession',
          })
        );
      }
    }
  };

  const connectUserToVoiceSession = async (
    voiceSessionId: string,
    audioElement: HTMLAudioElement | null
  ) => {
    try {
      dispatch(setVoiceSessionLoading(true));
      dispatch(setVoiceSessionStatus(VoiceSessionStatus.CONNECTING));
      if (!isTeaming) {
        ParticipantsStatusEventBus.hearing.$connecting.next(
          primaryHearingPhoneNumber
        );
      }

      await connectUserToExistingSession(
        voiceSessionId,
        agentNumber,
        audioElement
      );

      const voiceMeetingServiceInstance = VoiceMeetingService.getInstance();

      dispatch(
        setVoiceSession({
          sessionId: voiceMeetingServiceInstance.getSessionId(),
          status: VoiceSessionStatus.ACTIVE,
          isLoading: false,
        })
      );

      await setupListeners();
      voiceMeetingServiceInstance.onAudioVideoDidStart(() => {
        dispatch(hearingAudioReady());
      });
    } catch (error) {
      dispatch(resetVoiceSession());
      ParticipantsStatusEventBus.hearing.$disconnected.next({
        phoneNumber: primaryHearingPhoneNumber,
      });
      dispatch(
        handleError({
          error,
          methodName: 'connectUserToVoiceSession',
        })
      );
    }
  };

  return {
    createNewVoiceSession,
    connectUserToVoiceSession,
    replaceN11Number,
    clearCallErrorIfExists,
  };
};
