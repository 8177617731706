import { AppConfigService } from 'features/app-config/services';
import { N11DaoService } from 'features/n11/services';

interface N11Response {
  phoneNumber: string;
  n11Status: number;
}

/**
 * Checks if a phone number is a special number (N11 number or 988 crisis number)
 * @param phoneNumber - The phone number to check
 * @returns true if the phone number is a special number, false otherwise
 */
export const isSpecialPhoneNumber = (phoneNumber: string): boolean => {
  // TODO: 411 will be handled in a separate story in the future
  const specialNumberRegex = /^(211|311|511|711|811|988)$/;
  return specialNumberRegex.test(phoneNumber);
};

export const resolveN11PhoneNumber = async (
  originalNumber: string,
  callId: number
): Promise<N11Response> => {
  if (!isSpecialPhoneNumber(originalNumber)) {
    return { phoneNumber: originalNumber, n11Status: 0 };
  }

  // Handle 988 crisis number
  if (originalNumber === '988') {
    const suicidePreventionNumber = AppConfigService.suicidePreventionNumber;
    return {
      phoneNumber: suicidePreventionNumber,
      n11Status: suicidePreventionNumber.length ? 0 : 1,
    };
  }

  // Handle N11 numbers (211, 311, 511, 711, 811)
  const { PhoneNumber, N11Status } = await N11DaoService.getN11Number({
    phoneNumber: originalNumber,
    callId,
  });
  return { phoneNumber: PhoneNumber, n11Status: N11Status };
};
