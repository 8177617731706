import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import {
  isDeafConnectingSelector,
  isDeafDisconnectedSelector,
} from 'features/call/call-deaf/store';
import {
  isPrimaryHearingDisconnectedSelector,
  primaryHearingSelector,
  selectIsAnyHearingConnecting,
} from 'features/call/call-hearing/store';
import { VrsCallType } from 'features/call/vrs-call/enums';
import {
  EmergencyCallDetailsStatus,
  EmergencyCallType,
  EmergencyConnectionPoint,
} from 'features/emergency/enums';
import {
  isAcceptingUserSelector,
  isCollaborationInProgressSelector,
} from 'features/teaming/teaming-base/store';

export const emergencySelector = ({ emergency }: RootState) => emergency;

export const selectIsSpawningEmergencyCall = ({ emergency }: RootState) =>
  emergency.isSpawningCall;

export const emergencyConnectionPointSelector = ({ emergency }: RootState) =>
  emergency.connectionPoint;

export const isCalledToConnectionPointSelector = ({ emergency }: RootState) => {
  if (
    emergency.calledConnectionPoints &&
    emergency.connectionPoint &&
    emergency.calledConnectionPoints.includes(emergency.connectionPoint)
  ) {
    return true;
  }
  return false;
};

export const isOtherConnectionPointSelector = createSelector(
  emergencyConnectionPointSelector,
  (connectionPoint) => connectionPoint === EmergencyConnectionPoint.OTHER
);

export const isDefinedConnectionPointSelector = createSelector(
  emergencyConnectionPointSelector,
  isOtherConnectionPointSelector,
  (connectionPoint, isOtherConnectionPoint) =>
    connectionPoint && !isOtherConnectionPoint
);

export const isEmergencyCallTypeSelector = ({ emergency }: RootState) =>
  emergency?.callType === EmergencyCallType.EMERGENCY &&
  !emergency.isEmergencyDialback;

export const isEmergencyCallbackTypeSelector = ({ emergency }: RootState) =>
  emergency?.callType === EmergencyCallType.EMERGENCY_CALLBACK;

export const isEmergencyDialbackSelector = ({ emergency }: RootState) =>
  emergency.isEmergencyDialback;

export const isRegisteredAddressPendingSelector = ({ emergency }: RootState) =>
  emergency.registeredAddress.status === 'pending';

export const emergencyCallDetailsStatusSelector = ({ emergency }: RootState) =>
  emergency.callDetailsStatus;

export const emergencyIsNotAtRegisteredAddressSelector = ({
  emergency,
}: RootState) =>
  !emergency.isAddressVerificationNeeded &&
  !emergency.isEmergencyOccurredAtRegisteredLocation;

export const isEmergencyCalleeReconnectSelector = createSelector(
  isPrimaryHearingDisconnectedSelector,
  emergencyCallDetailsStatusSelector,
  (isPrimaryHearingDisconnected, status) =>
    isPrimaryHearingDisconnected &&
    status === EmergencyCallDetailsStatus.CALLEE_RECONNECT
);

export const phoneNumberECRCSelector = ({ emergency }: RootState) =>
  emergency.phoneNumbers.ECRC;

export const phoneNumberPSAPSelector = ({ emergency }: RootState) =>
  emergency.phoneNumbers.PSAP;

export const emergencyPhoneNumberSelector = createSelector(
  emergencyConnectionPointSelector,
  phoneNumberECRCSelector,
  phoneNumberPSAPSelector,
  (connectionPoint, phoneNumberECRC, phoneNumberPSAP) => {
    if (connectionPoint === EmergencyConnectionPoint.PSAP) {
      return phoneNumberPSAP;
    }

    if (connectionPoint === EmergencyConnectionPoint.ECRC) {
      return phoneNumberECRC;
    }

    return '';
  }
);

export const emergencyAddressInformationSelector = createSelector(
  emergencySelector,
  (emergency) => {
    return {
      isGeoCoordinateAvailable: emergency.isGeoCoordinateAvailable,
      verify911Address: emergency.verify911Address,
    };
  }
);

export const isAddressVerificationNeededSelector = ({ emergency }: RootState) =>
  emergency.isAddressVerificationNeeded;

export const emergencyRegisteredAddressNameSelector = ({
  emergency,
}: RootState) => emergency.registeredAddress.name;

export const selectIsEmergencyDeafReconnectionNeeded = ({
  emergency,
}: RootState) => emergency.isDeafReconnectionNeeded;

const isEmergencyCallSelector = ({ vrsCall }: RootState) =>
  vrsCall.callType === VrsCallType.EMERGENCY_CALL;

export const selectIsEmergencyStartDisabled = createSelector(
  selectIsSpawningEmergencyCall,
  isEmergencyCallSelector,
  isDeafDisconnectedSelector,
  isDeafConnectingSelector,
  selectIsAnyHearingConnecting,
  isCollaborationInProgressSelector,
  isAcceptingUserSelector,
  (
    isSpawningEmergencyCall,
    isEmergencyCall,
    isDeafDisconnected,
    isDeafConnecting,
    isAnyHearingConnecting,
    isTeamingCollaborationInProgress,
    isUserAcceptingTeaming
  ) => {
    if (isEmergencyCall || isSpawningEmergencyCall) {
      return true;
    }

    if (isDeafDisconnected || isDeafConnecting) {
      return true;
    }

    if (isAnyHearingConnecting) {
      return true;
    }

    if (isTeamingCollaborationInProgress || isUserAcceptingTeaming) {
      return true;
    }

    return false;
  }
);

export const selectIsAbandonedEmergencyCall = ({ emergency }: RootState) =>
  emergency.isAbandonedEmergencyCall;

export const selectShouldCancelHearingBeDisabledForEmergencyCall = () =>
  createSelector(
    selectIsAbandonedEmergencyCall,
    isEmergencyDialbackSelector,
    primaryHearingSelector,
    (
      isAbandonedEmergencyCall,
      isEmergencyDialback,
      primaryHearingPhoneNumber
    ) =>
      (isAbandonedEmergencyCall || isEmergencyDialback) &&
      !primaryHearingPhoneNumber.hasConnected
  );

export const selectIsLoggedOutEmergencyCall = ({ emergency }: RootState) =>
  emergency.isLoggedOutEmergencyCall;
