import type { FC } from 'react';
import { useEffect } from 'react';

import { useProductInfo } from 'features/product/hooks/';
import { ProductSipHeader } from 'features/product/services/';

export const ProductSipHeaderSync: FC = () => {
  const { productName, version } = useProductInfo();

  useEffect(() => {
    ProductSipHeader.updateProductInfo(productName, version);
  }, [productName, version]);

  return null;
};
