import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

import { httpClient } from 'common/services';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { sipSettings } from 'features/sip/config';
import { setUserEmail } from 'features/user/store';
import { selectIsInCallerSession } from 'features/caller-session/store';

export const useAuthorization = () => {
  const dispatch = useAppDispatch();
  const isInCallerSession = useAppSelector(selectIsInCallerSession);
  const {
    events,
    user,
    isLoading: isAuthLoading,
    isAuthenticated,
    signinRedirect,
  } = useAuth();

  const setJwtToken = (token: string) => {
    sipSettings.update({ authorization_jwt: `Bearer ${token}` });
    httpClient.setHeader('Authorization', token);
  };

  useEffect(() => {
    return events.addSilentRenewError(() => {
      if (!isInCallerSession) {
        signinRedirect();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInCallerSession]);

  useEffect(() => {
    if (!isAuthLoading && !isAuthenticated && !isInCallerSession) {
      signinRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isAuthLoading, isInCallerSession]);

  useEffect(() => {
    const token = user?.id_token;
    if (!token) {
      return;
    }

    setJwtToken(token);
    dispatch(setUserEmail(user.profile.email!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return user?.id_token ?? null;
};
