import type { Options } from 'recordrtc';
import { RecordRTCPromisesHandler } from 'recordrtc';

const options: Options = {
  type: 'video',
  mimeType: 'video/webm;codecs=h264', // mp4 directly not supported
};

export class SignMailRecorderService {
  private static instance: SignMailRecorderService;
  private recorder: RecordRTCPromisesHandler | null = null;
  private recordingStartDate: Date | null = null;
  private videoStream: MediaStream | null = null;

  private constructor() {}

  public static getInstance(): SignMailRecorderService {
    if (!SignMailRecorderService.instance) {
      SignMailRecorderService.instance = new SignMailRecorderService();
    }
    return SignMailRecorderService.instance;
  }

  public setVideoStream(videoStream: MediaStream) {
    this.videoStream = videoStream;
  }

  public async start(): Promise<MediaStream> {
    const stream = this.videoStream!;
    this.recorder = new RecordRTCPromisesHandler(stream, options);
    await this.recorder.startRecording();
    this.setRecordingStartDate();
    return stream;
  }

  public async stop(callId: number): Promise<{ file: File; duration: number }> {
    if (!this.recorder) {
      throw new Error('Recorder not found');
    }

    await this.recorder.stopRecording();

    const stopRecordingDate = new Date();
    const diff =
      stopRecordingDate.valueOf() - this.recordingStartDate!.valueOf();
    const diffSeconds = Math.round(diff / 1000);

    const blob = await this.recorder.getBlob();
    const file = new File([blob], `${callId}.mp4`, {
      type: 'video/mp4',
    });
    this.resetRecordingStartDate();

    return {
      file,
      duration: diffSeconds,
    };
  }

  public destroy() {
    this.recorder?.destroy();
    console.info('SignMail Recorder Destroyed');
  }

  public getRecordingStartDate() {
    return this.recordingStartDate;
  }

  private setRecordingStartDate() {
    this.recordingStartDate = new Date();
  }

  private resetRecordingStartDate() {
    this.recordingStartDate = null;
  }
}
