import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { Button } from 'features/ui/elements';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

interface CallDetailsButtonDeafThreeDotMenuItemProps {
  loading?: boolean;
  hotkey?: any;
  disabled?: boolean;
  onClick?: () => void | Promise<void>;
}

export const CallDetailsButtonDeafThreeDotMenuItem: React.FC<
  CallDetailsButtonDeafThreeDotMenuItemProps
> = ({ onClick, disabled, loading, hotkey }) => {
  return (
    <ThreeDotButtonStyle
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      hotkey={hotkey}
      aria-label='call-details-deaf-three-menu-button'
    >
      <MoreHorizIcon className='w-5 h-5 text-white' />
    </ThreeDotButtonStyle>
  );
};

const ThreeDotButtonStyle = styled(Button)`
  background-color: ${theme.palette.grey[800]};
  color: ${theme.palette.common.white};
  border: none;
  height: 45px;
  margin-left: 5px;

  &:hover {
    background-color: ${theme.palette.grey[700]};
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:focus-visible {
    outline: none;
    box-shadow: none;
  }
`;
