import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'features/app/store/store';
import { ConferenceType } from 'features/call/call-conference/enums';
import { teamingRoomUriSelector } from 'features/teaming/teaming-base/store';

export const conferenceTypeSelector = ({ conference }: RootState) =>
  conference.type;

export const isZoomConferenceSelector = createSelector(
  conferenceTypeSelector,
  (type) => type === ConferenceType.ZOOM
);

export const selectIsCurrentlyInterpreting = ({ conference }: RootState) =>
  conference.isCurrentlyInterpreting;

export const conferenceAppNameSelector = createSelector(
  isZoomConferenceSelector,
  (isZoomConference) => (isZoomConference ? 'Zoom' : 'Teams')
);

export const isNotZoomConferenceSelector = createSelector(
  conferenceTypeSelector,
  (type) => [ConferenceType.TEAMS, ConferenceType.FACE_TIME].includes(type)
);

export const isJoinedToZoomSelector = ({ conference }: RootState) =>
  conference.isJoinedToZoom;

export const isStartConferenceCallTimerSelector = createSelector(
  isNotZoomConferenceSelector,
  selectIsCurrentlyInterpreting,
  (isNotZoomConference, isCurrentlyInterpreting) =>
    isNotZoomConference || isCurrentlyInterpreting
);

const regularConferenceUrlSelector = ({ conference }: RootState) =>
  conference.url;

export const conferenceUrlSelector = createSelector(
  regularConferenceUrlSelector,
  teamingRoomUriSelector,
  (regularConferenceUrl, teamingRoomUri) =>
    regularConferenceUrl || teamingRoomUri
);

export const isConferenceEmptySelector = ({ conference }: RootState) =>
  conference.type === ConferenceType.NONE;

export const isFaceTimeConferenceSelector = ({ conference }: RootState) =>
  conference.type === ConferenceType.FACE_TIME;

export const isTeamsConferenceSelector = ({ conference }: RootState) =>
  conference.type === ConferenceType.TEAMS;

export const isRegularConferenceTypeSelector = createSelector(
  isZoomConferenceSelector,
  isTeamsConferenceSelector,
  (isZoomConference, isTeamsConference) => isZoomConference || isTeamsConference
);

export const participantCountSelector = (state: RootState): number =>
  state.conference.participantCount;

export const selectDidVrsAgentGetConnectedToConferenceRoomSelector = ({
  conference,
}: RootState) => conference.vrsAgentConnectedToConferenceRoom;
