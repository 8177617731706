import { useProductInfo } from './useProductInfo';

import { ProductSipHeaderTag } from 'features/product/enums';
import { sorensonSipVersion } from 'features/sip/constants';

export const useProductSipHeader = () => {
  const { productName, version } = useProductInfo();

  return {
    sipHeader: {
      [ProductSipHeaderTag.NAME]: productName,
      [ProductSipHeaderTag.VERSION]: version,
      [ProductSipHeaderTag.SORENSON_SIP_VERSION]: sorensonSipVersion,
    },
  };
};
