import type { Action, ThunkAction } from '@reduxjs/toolkit';

import { isDeafMutedSelector } from 'features/call/call-deaf/store/';
import { LogLevel } from 'features/analytics/enums';
import { AnalyticsDaoService } from 'features/analytics/services';
import type { RootState } from 'features/app/store/store';
import { LoggerService } from 'features/logger/services';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import {
  isTwoLineVcoSelector,
  isVcoRequestedSelector,
} from 'features/vco/store';
import { isMicrophoneMutedSelector } from 'features/call/call-base/store';

interface AudioFlowPayload {
  toDirectLine: string[];
  toVoiceLine: string[];
  audible: string[];
}

export const sendAudioFlowAnalytics = (
  payload: AudioFlowPayload
): ThunkAction<Promise<void>, RootState, unknown, Action> => {
  return async (_dispatch: unknown, getState: () => RootState) => {
    try {
      const state = getState();
      const callId = callIdSelector(state);
      const isVcoRequested = isVcoRequestedSelector(state);
      const isTwoLineVco = isTwoLineVcoSelector(state);
      const isDeafMuted = isDeafMutedSelector(state);
      const isVisMicMuted = isMicrophoneMutedSelector(state);
      await AnalyticsDaoService.sendAnalytics({
        Method: 'AudioFlowChange',
        CallId: callId,
        ToDirectLine: JSON.stringify(payload.toDirectLine),
        ToVoiceLine: JSON.stringify(payload.toVoiceLine),
        Audible: JSON.stringify(payload.audible),
        Level: LogLevel.INFO,
        Message: 'Audio flow changed',
        IsVcoRequested: isVcoRequested,
        IsTwoLineVco: isTwoLineVco,
        IsDeafMuted: isDeafMuted,
        IsVisMicMuted: isVisMicMuted,
      });
    } catch (error) {
      LoggerService.error(error);
    }
  };
};
