import { envConfig } from 'features/environment/services';

const isDevEnvironment = () => envConfig.environment === 'DEVELOP';
export const isQaEnvironment = () => envConfig.environment === 'QA';

export const isProductionEnvironment = () =>
  !isDevEnvironment() && !isQaEnvironment();

export const isDevOrQaEnvironment = () =>
  isDevEnvironment() || isQaEnvironment();
