import type { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { Subject } from 'rxjs';

export interface ConnectionInfo {
  connection: HubConnection;
  connectionId: string | null;
  state: HubConnectionState;
  baseUrl: string;
}

export interface ReconnectingInfo {
  error?: Error;
  connectionId: string | null;
  state: HubConnectionState;
}

export interface CloseInfo {
  error?: Error;
  connectionId: string | null;
  state: HubConnectionState;
  baseUrl: string;
}

export interface ReconnectedInfo {
  connectionId?: string;
  previousConnectionId: string | null;
  state: HubConnectionState;
}

/** NOTE: There are two RnsEventBus classes in Mercury.
 * Be careful which you use.
 * This is a problem and we should fix it someday.
 */
export class RnsEventBus {
  static readonly $connected = new Subject<ConnectionInfo>();
  static readonly $reconnecting = new Subject<ReconnectingInfo>();
  static readonly $reconnected = new Subject<ReconnectedInfo>();
  static readonly $close = new Subject<CloseInfo>();
}
