import { useIMask } from 'react-imask';

import { maskValidate } from 'features/phone-number/constants';

export const useValidatePhoneNumberInput = () => {
  const { ref, unmaskedValue, maskRef, setValue } = useIMask<HTMLInputElement>({
    mask: maskValidate,
  });

  const isPhoneNumberValid = (maskRef.current?.unmaskedValue?.length ?? 0) >= 3;

  return { ref, unmaskedValue, maskRef, setValue, isPhoneNumberValid };
};
