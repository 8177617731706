import { delay, firstValueFrom, of, race } from 'rxjs';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import { deafPhoneNumberSelector } from 'features/call/call-deaf/store';
import { setPrimaryHearingDirection } from 'features/call/call-hearing/store';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import {
  adminContactSelector,
  isMonitorHostIpExistsSelector,
} from 'features/monitoring/store/selector';
import { userNameSelector } from 'features/user/store';
import { createAgentParticipant } from 'features/voice-meeting/helpers';
import {
  useVoiceMeetingConnection,
  useVoiceMeetingContext,
  useVoiceMeetingSession,
  useVoiceMeetingSetupListeners,
} from 'features/voice-meeting/hooks';
import type { CreateVoiceMeetingParams } from 'features/voice-meeting/interfaces';
import { VoiceSessionStatus } from 'features/voice-session/enums';
import { setVoiceSession } from 'features/voice-session/store';
import { isVrsModeSelector } from 'features/multi-mode/store';

export const useVoiceMeetingSessionConnect = () => {
  const dispatch = useAppDispatch();
  const {
    createMeetingSessionFromState,
    bindAudio,
    addParticipant,
    createVoiceSession,
  } = useVoiceMeetingConnection();

  const { audio, service } = useVoiceMeetingContext();
  const { startMeeting, addMonitorParticipant } = useVoiceMeetingSession();
  const isCallMonitoring = useAppSelector(isMonitorHostIpExistsSelector);
  const adminContact = useAppSelector(adminContactSelector);
  const { setupListeners } = useVoiceMeetingSetupListeners();
  const agentName = useAppSelector(userNameSelector);
  const currentCallId = useAppSelector(callIdSelector);
  const deafPhoneNumber = useAppSelector(deafPhoneNumberSelector);
  const isVrs = useAppSelector(isVrsModeSelector);

  // DO NOT REMOVE THIS, AS IT WILL CAUSE CONNECTS FOR BILLING TO POTENTIALLY FAIL.
  // ONLY REMOVE THIS ONCE WE ARE NOT USING THE WEBSOCKET FOR BILLING CONNECTS.
  const awaitForWSConnect = async () => {
    const timeout$ = of('Timeout').pipe(delay(60000));
    return await firstValueFrom(
      race(ParticipantsStatusEventBus.hearing.$connectedToWebsocket, timeout$)
    );
  };

  const createSession = async ({
    agentUserName,
    ...rest
  }: CreateVoiceMeetingParams) => {
    const voiceSessionState = await createVoiceSession({
      agentUserName,
      ...rest,
    });

    return {
      session: createMeetingSessionFromState(voiceSessionState, agentUserName),
      voiceSessionState,
    };
  };

  const connectUserToExistingSession = async (
    sessionId: string,
    agentId: string,
    audioElement: HTMLAudioElement | null
  ) => {
    if (!audioElement) {
      throw Error('DEBUG: audioElement not exists');
    }

    if (isCallMonitoring) {
      await addMonitorParticipant(adminContact, sessionId);
    }

    const voiceSessionState = await addParticipant({
      participant: createAgentParticipant(agentId),
      sessionId,
    });

    const meetingSession = createMeetingSessionFromState(
      voiceSessionState,
      agentId
    );

    await bindAudio(meetingSession, audioElement);

    service.setMeetingSession(meetingSession);
    service.setVoiceSession(voiceSessionState);

    startMeeting();
  };

  const startNewSession = async ({
    agentUserName,
    hearingPhoneNumber,
    ...rest
  }: CreateVoiceMeetingParams) => {
    if (!audio) {
      throw Error('DEBUG: audioElement not exists');
    }

    const { session, voiceSessionState } = await createSession({
      agentUserName,
      hearingPhoneNumber,
      ...rest,
    });

    await bindAudio(session, audio);

    service.setMeetingSession(session);
    service.setVoiceSession(voiceSessionState);

    const hearingUser = service.getHearingByPhoneNumber(hearingPhoneNumber);

    if (hearingUser?.direction) {
      dispatch(setPrimaryHearingDirection(hearingUser?.direction));
    }

    if (hearingUser) {
      ParticipantsStatusEventBus.hearing.$connectingId.next({
        id: hearingUser.id,
        phoneNumber: hearingPhoneNumber,
      });
    }
    const voiceSessionId = service.getSessionId();

    if (isCallMonitoring) {
      await addMonitorParticipant(adminContact, voiceSessionId);
    }

    dispatch(
      setVoiceSession({
        sessionId: voiceSessionId,
        status: VoiceSessionStatus.ACTIVE,
        isLoading: false,
      })
    );
    await setupListeners();
    if (isVrs) {
      await awaitForWSConnect();
    }

    await startMeeting();
  };

  const startNewVoiceSessionOnlyAgent = async () => {
    if (!audio) {
      throw Error('DEBUG: audioElement not exists');
    }

    const { session, voiceSessionState } = await createSession({
      agentUserName: agentName,
      callId: currentCallId,
      deafPhoneNumber,
      hearingPhoneNumber: '',
    });

    await bindAudio(session, audio);

    service.setMeetingSession(session);
    service.setVoiceSession(voiceSessionState);

    const voiceSessionId = service.getSessionId();

    if (isCallMonitoring) {
      await addMonitorParticipant(adminContact, voiceSessionId);
    }

    dispatch(
      setVoiceSession({
        sessionId: voiceSessionId,
        status: VoiceSessionStatus.ACTIVE,
        isLoading: false,
      })
    );
    await setupListeners();
    startMeeting();
  };

  return {
    connectUserToExistingSession,
    startNewSession,
    startNewVoiceSessionOnlyAgent,
  };
};
