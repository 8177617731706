import { useCallback, type MutableRefObject } from 'react';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import { handleError } from 'features/notification/store';
import {
  SignMailDaoService,
  SignMailRecorderService,
} from 'features/signmail/services';
import {
  handleMailboxFull,
  recordingStarted,
  resetSignMailState,
} from 'features/signmail/store';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import { useMediaStream } from 'features/media-streams/hooks';

export const useSignMailRecording = () => {
  const { getVideoStream } = useMediaStream();
  const service = SignMailRecorderService.getInstance();
  const callId = useAppSelector(callIdSelector);
  const dispatch = useAppDispatch();

  const start = useCallback(
    async (videoRef: MutableRefObject<HTMLVideoElement | null>) => {
      const videoStream = await getVideoStream();
      if (!videoStream) {
        return;
      }
      service.setVideoStream(videoStream);
      try {
        const { UploadURL: uploadUrl, MailboxFull } =
          await SignMailDaoService.getUploadUrl(callId);

        if (MailboxFull) {
          dispatch(handleMailboxFull());
          return;
        }

        videoRef.current!.srcObject = await service.start();
        dispatch(recordingStarted({ uploadUrl }));
      } catch (error) {
        ParticipantsStatusEventBus.deaf.$disconnectSignMail.next();
        dispatch(
          handleError({
            error,
            methodName: 'start',
          })
        );
        dispatch(resetSignMailState());
        service.destroy();
      }
    },
    [callId, dispatch, getVideoStream, service]
  );
  return {
    start,
  };
};
