import { useEffect, useRef, useState } from 'react';
import { Box, styled } from '@mui/material';

import {
  CallDetailsButtonDeafThreeDotMenuItem,
  CallDetailsButtonDeafAddTwoLineVcoItem,
} from '..';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { setCallDetailsStatus } from 'features/call/call-details/store';
import { CallDetailsStatus } from 'features/call/call-details/enums';
import {
  isTwoLineVcoSelector,
  isVcoRequestedSelector,
} from 'features/vco/store';
import { EmergencyCallDetailsStatus } from 'features/emergency/enums';
import { setEmergencyCallDetailsStatus } from 'features/emergency/store';

export const CallDetailsDeafThreeDotMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const isTwoLineVcoEnabled = useAppSelector(isTwoLineVcoSelector);
  const isVcoRequested = useAppSelector(isVcoRequestedSelector);
  const showAddVco2Line = !isTwoLineVcoEnabled && !isVcoRequested;
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleAddVCOLine = () => {
    dispatch(setCallDetailsStatus(CallDetailsStatus.NEW_VCO_2_LINE));
    dispatch(
      setEmergencyCallDetailsStatus(EmergencyCallDetailsStatus.NEW_VCO_2_LINE)
    );
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <>
      {showAddVco2Line && (
        <RootContainer>
          <CallDetailsButtonDeafThreeDotMenuItem onClick={toggleMenu} />
          {isOpen && (
            <CallDetailsButtonDeafAddTwoLineVcoItem
              onClick={handleAddVCOLine}
            />
          )}
        </RootContainer>
      )}
    </>
  );
};

const RootContainer = styled(Box)`
  position: relative;
`;
