import { IconButton, Typography } from '@mui/material';
import type { MouseEvent, ReactNode } from 'react';
import { forwardRef } from 'react';

import { MarkIcon } from 'assets/svg';
import styled, { styledProps } from 'features/theme/styled';
import theme from 'features/theme/theme';

export interface MenuIconButtonProps {
  title: string;
  icon: ReactNode;
  inactiveIcon?: ReactNode;
  isDisabled: boolean;
  isCheckIconVisible: boolean;
  isChecked: boolean;
  textColor?: string;
  testId?: string;
  onClickHandler?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const Container = styled('div')`
  display: inline-block;
  text-align: center;
`;

const StyledIcons = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 25px;
`;

const StyledCheckIcon = styledProps(MarkIcon)<{ $isVisible: boolean }>`
  width: ${({ $isVisible }) => ($isVisible ? '16px' : '0')};
  margin-left: ${({ $isVisible }) => ($isVisible ? theme.spacing(4) : '0')};
  transition: width 0.2s ease-in-out;
`;

export const MenuIconButton = forwardRef<
  HTMLButtonElement,
  MenuIconButtonProps
>(
  (
    {
      title,
      icon,
      inactiveIcon = null,
      isDisabled,
      isCheckIconVisible,
      isChecked,
      testId = '',
      textColor = theme.palette.common.white,
      onClickHandler = () => void 0,
    },
    ref
  ) => {
    const isShowCheckIcon = isCheckIconVisible && isChecked;
    const isShowInactiveIcon = inactiveIcon && !isChecked;

    return (
      <IconButton
        ref={ref}
        disableRipple
        size='small'
        disabled={isDisabled}
        data-testid={testId}
        onClick={onClickHandler}
      >
        <Container>
          <StyledIcons>
            {isShowInactiveIcon ? inactiveIcon : icon}
            <StyledCheckIcon
              data-testid={testId ? `${testId}-mark-icon` : undefined}
              title='mark-icon'
              $isVisible={isShowCheckIcon}
            />
          </StyledIcons>
          <Typography color={textColor} variant='textMediumRegular'>
            {title}
          </Typography>
        </Container>
      </IconButton>
    );
  }
);
