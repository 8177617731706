import {
  DEFAULT_CALL_ANSWER_TIMEOUT_SEC,
  DEFAULT_INTERPRETING_TIMER_SEC,
  DEFAULT_SERVICE_UNREGISTERED_HEARING_TO_DEAF_CALLS,
  DEFAULT_SESSION_UPDATE_TIMER_SEC,
} from 'features/app-config/constants';
import type { AppConfig, Languages } from 'features/app-config/interfaces';
import { LoggerService } from 'features/logger/services';

export class AppConfigService {
  private static appConfig: AppConfig = {} as AppConfig;

  public static setAppConfig(config: AppConfig) {
    this.appConfig = config;
  }

  private static getConfigValue(key: keyof AppConfig) {
    const value = this.appConfig[key];
    if (typeof value === 'undefined') {
      LoggerService.error(`Missed config field: ${key}`);
    }

    return value;
  }

  public static get callAnswerTimeout() {
    return (
      Number(this.getConfigValue('CallAnswerTimeoutSeconds')) ||
      DEFAULT_CALL_ANSWER_TIMEOUT_SEC
    );
  }

  public static get defaultOutboundCallerIdEnglish() {
    return (
      this.getConfigValue('DefaultOutboundCallerId_English') || '8663278877'
    );
  }

  public static get defaultOutboundCallerIdSpanish() {
    return (
      this.getConfigValue('DefaultOutboundCallerId_Spanish') || '8669877528'
    );
  }

  public static get suicidePreventionNumber(): string {
    const value = this.getConfigValue('SuicidePreventionConnectionString');
    if (typeof value === 'string' && value?.length > 0) {
      return value;
    }
    return '8002738255';
  }

  public static get autoUpdateSessionActivityTime() {
    return (
      Number(this.getConfigValue('SessionUpdateTimerSeconds')) ||
      DEFAULT_SESSION_UPDATE_TIMER_SEC
    );
  }

  public static get interpretingTimerSeconds() {
    return (
      Number(this.getConfigValue('InterpretingTimerSeconds')) ||
      DEFAULT_INTERPRETING_TIMER_SEC
    );
  }

  public static get activeLanguages(): Languages[] {
    return (this.getConfigValue('ActiveLanguages') as Languages[]) || [];
  }

  public static get isCheckOnRegistrationNeed() {
    //Returns a boolean value instead of "TRUE" or "FALSE"
    return (
      !JSON.parse(
        String(
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          this.getConfigValue('ServiceUnregisteredHearingToDeafCalls')
        ).toLocaleLowerCase()
      ) || DEFAULT_SERVICE_UNREGISTERED_HEARING_TO_DEAF_CALLS
    );
  }
}
