import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';

import {
  clearDialedNumber,
  selectDialedNumberOnNumberPad,
  selectShowInputToConsumer,
  toggleShowInputToConsumer,
} from './dialPadSlice';

import type { DialPadValue } from 'features/dial-pad/interfaces';
import { DialPadDaoService, DialPadEventBus } from 'features/dial-pad/services';
import { handleError } from 'features/notification/store';
import { VrsChatEventBus } from 'features/vrs-chat/services';
import { messageToClearVPText } from 'features/vrs-chat/components';
import { SipSessionEventBus } from 'features/sip/services';
import { LogLevel } from 'features/analytics/enums';
import { sendAnalyticsInfo } from 'features/analytics/helpers';
import type { RootState } from 'features/app/store/store';

// outbound thunk we want to send to both hearing and deaf participants as well as the dialpad state
export const sendDtmfThunkOutbound = createAsyncThunk(
  'dialPad/sendDtmfOutbound',
  async (
    {
      value,
      participantId,
      sessionId,
      isDeafConnected,
    }: {
      value: DialPadValue;
      participantId?: string;
      sessionId: string;
      isDeafConnected: boolean;
    },
    { dispatch, getState }
  ) => {
    const stringValue = String(value);
    const state = getState() as RootState;
    const shouldSendDtmfThroughShareText = selectShowInputToConsumer(state);
    try {
      if (isDeafConnected) {
        DialPadEventBus.sendDtmf$.next({
          digit: stringValue,
          shouldSendDtmfThroughShareText,
        });
      }
      if (participantId) {
        await DialPadDaoService.sendDtmf({
          digit: stringValue,
          hearingParticipantId: participantId,
          sessionId,
        });
      }
      dispatch(
        sendAnalyticsInfo({
          Method: 'handleSendDtmf sent from VI',
          Level: LogLevel.INFO,
          Message: 'DTMF Sent',
        })
      );
    } catch (error) {
      dispatch(
        handleError({
          error,
          title: t('dtmfDigitNotSent'),
          methodName: 'sendDtmf',
        })
      );
      throw error;
    }
  }
);
// inbound thunk we only want to send to hearing participant
export const sendDtmfThunkInbound = createAsyncThunk(
  'dialPad/sendDtmfInbound',
  async (
    {
      value,
      participantId,
      sessionId,
    }: {
      value: DialPadValue;
      participantId?: string;
      sessionId: string;
    },
    { dispatch }
  ) => {
    try {
      if (participantId) {
        await DialPadDaoService.sendDtmf({
          digit: String(value),
          hearingParticipantId: participantId,
          sessionId,
        });
        dispatch(
          sendAnalyticsInfo({
            Method: 'handleSendDtmf sent from inbound participant',
            Level: LogLevel.INFO,
            Message: 'DTMF Sent',
          })
        );
      }
    } catch (error) {
      dispatch(
        handleError({
          error,
          title: t('dtmfDigitNotSent'),
          methodName: 'sendDtmf',
        })
      );
      throw error;
    }
  }
);

export const toggleShowInputToConsumerThunk = createAsyncThunk(
  'dialPad/toggleShowInputToConsumer',
  async (_, { dispatch, getState }) => {
    dispatch(toggleShowInputToConsumer());
    const stateAfterToggle = getState();
    const dialedNumber = selectDialedNumberOnNumberPad(
      stateAfterToggle as RootState
    );
    const showInputToConsumer = selectShowInputToConsumer(
      stateAfterToggle as RootState
    );
    // if we are turning off the input to consumer, we need to clear the dialed number and the shared text
    if (!showInputToConsumer) {
      dispatch(clearSharedTextAndDialPadThunk(dialedNumber));
    }
    dispatch(
      sendAnalyticsInfo({
        event: 'dialpadSettingsMenuButton',
        value: showInputToConsumer,
        method: 'toggleShowInputToConsumerThunk',
      })
    );
  }
);
export const clearSharedTextAndDialPadThunk = createAsyncThunk(
  'dialPad/clearDialPadAndClearSharedText',
  async (dialedNumber: string, { dispatch }) => {
    const encoder = new TextEncoder();
    const backspace = encoder.encode('\b');

    for (const _ of dialedNumber) {
      VrsChatEventBus.$sendMessage.next(backspace);
    }
    dispatch(clearDialedNumber());

    SipSessionEventBus.outgoingInfo$.next(messageToClearVPText);
    VrsChatEventBus.$clearMessage.next();
  }
);
