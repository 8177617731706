import packageJson from '../../../../package.json';

const DEFAULT_PRODUCT_NAME = packageJson.name;
const ALTERNATE_PRODUCT_NAME = `Sorenson Videophone ${packageJson.name}`;

export const getEndpointName = (useAlternateName?: boolean): string => {
  return useAlternateName ? ALTERNATE_PRODUCT_NAME : DEFAULT_PRODUCT_NAME;
};

export const endpointName = `Sorenson Videophone ${packageJson.name}`;
