import { Typography } from '@mui/material';
import type { Dispatch, FC } from 'react';

import { CallPhoneIcon } from 'assets/svg';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

interface DialedNumbersHistoryListProps {
  dialedNumbersList: string[];
  onSetPhoneNumber: Dispatch<string>;
}

const DialedNumbersHistoryContainer = styled('div')`
  display: flex;
  max-height: 248px;
  overflow-y: auto;
  flex-direction: column;
  margin-top: ${theme.spacing(8)};
`;

const DialedNumberHistoryItem = styled(Typography)`
  display: flex;
  justify-content: space-between;
  color: ${theme.palette.common.white};
  padding-top: ${theme.spacing(4)};
  padding-bottom: ${theme.spacing(4)};
  padding-left: ${theme.spacing(6)};
  cursor: pointer;
  outline: none;
  &:hover {
    border: 1px solid transparent ${theme.palette.grey[600]};
    background-color: ${theme.palette.primary[900]};
  }
`;

const CallPhoneStyleIcon = styled(CallPhoneIcon)`
  margin-right: ${theme.spacing(6)};
  vertical-align: middle;
`;

export const DialedNumbersHistoryList: FC<DialedNumbersHistoryListProps> = ({
  dialedNumbersList,
  onSetPhoneNumber,
}) => {
  return (
    <DialedNumbersHistoryContainer data-testid='dialed-numbers-history-container'>
      {dialedNumbersList.map((dialedNumber) => (
        <DialedNumberHistoryItem
          variant='textMediumRegular'
          key={dialedNumber}
          onClick={() => onSetPhoneNumber(dialedNumber)}
        >
          {dialedNumber}
          <CallPhoneStyleIcon role='img' />
        </DialedNumberHistoryItem>
      ))}
    </DialedNumbersHistoryContainer>
  );
};
