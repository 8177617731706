import { useCallback, useEffect, useRef } from 'react';

import { SignMailRecordingStatusWidget } from 'features/signmail/components';
import { maxVideoRecordingDuration } from 'features/signmail/constants';
import { useSignMailRecording } from 'features/signmail/hooks';
import { SignMailEventBus } from 'features/signmail/services';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';

export const SignMailRecording = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { start } = useSignMailRecording();
  const hasStartedRef = useRef(false);

  const clearRecordingTimer = useCallback(() => {
    if (timerRef.current) {
      console.log('DEBUG: clearing timeout from SignMailRecording');
      clearTimeout(timerRef.current);
    }
  }, []);

  const startRecording = useCallback(async () => {
    if (hasStartedRef.current) {
      return;
    }
    if (!videoRef.current) {
      console.warn('videoRef.current is null; recording not started');
      return;
    }
    hasStartedRef.current = true;
    await start(videoRef);
    timerRef.current = setTimeout(() => {
      SignMailEventBus.recordingFinished$.next();
      ParticipantsStatusEventBus.deaf.$disconnectSignMail.next();
    }, maxVideoRecordingDuration);
  }, [start]);

  const handleVideoRef = useCallback(
    (node: HTMLVideoElement | null) => {
      videoRef.current = node;
      if (node) {
        startRecording();
      }
    },
    [startRecording]
  );

  useEffect(() => {
    return clearRecordingTimer; // Cleanup on unmount
  }, [clearRecordingTimer]);

  return (
    <Container data-testid='signmail-recording'>
      <SignMailRecordingStatusWidget />
      <StyledVideo ref={handleVideoRef} autoPlay />
    </Container>
  );
};

const Container = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${theme.palette.grey[900]};
`;

const StyledVideo = styled('video')`
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
`;
