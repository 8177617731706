import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

import packageJson from '../../../../package.json';

import { getEndpointName } from 'features/sip/constants';

const buildNumber = process.env.VITE_BUILD_NUMBER ?? '';

export const useProductInfo = () => {
  const flags = useFlags();

  return useMemo(() => {
    const productName = getEndpointName(flags.sinfoAlternateProductName);
    const version =
      buildNumber && buildNumber !== 'undefined' && buildNumber.trim() !== ''
        ? `${packageJson.version}.${buildNumber}`
        : packageJson.version;

    return {
      productName,
      version,
      buildNumber,
    };
  }, [flags.sinfoAlternateProductName]);
};
