class AudioContextService {
  private static instance: AudioContextService | null = null;
  private audioContext: AudioContext | null = null;

  private constructor() {}

  static getInstance(): AudioContextService {
    if (!AudioContextService.instance) {
      AudioContextService.instance = new AudioContextService();
    }
    return AudioContextService.instance;
  }

  getContext(): AudioContext {
    if (!this.audioContext || this.audioContext.state === 'closed') {
      console.log(
        'Debug: AudioContext was null or closed, creating new instance'
      );
      this.audioContext = new AudioContext();
    }

    if (this.audioContext.state === 'suspended') {
      console.log('Debug: AudioContext was suspended, resuming');
      this.audioContext.resume();
    }

    return this.audioContext;
  }

  async close() {
    console.log('closing audio context');
    if (!this.audioContext || this.audioContext.state === 'closed') {
      return;
    }

    try {
      await this.audioContext.close();
    } finally {
      this.audioContext = null;
    }
  }
}

export { AudioContextService };
export const audioContextService = AudioContextService.getInstance();
