import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { AppState } from 'features/app/store/app/interfaces/appState';

export const initialAppState: AppState = {
  isInnerLoading: false,
  isRnsConnectionSuccess: false,
  isSipConnectionSuccess: false,
  isUnloading: false,
  isRnsConnectionClosed: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    setInnerLoading: (state, action: PayloadAction<boolean>) => {
      state.isInnerLoading = action.payload;
    },
    rnsAttemptingToReconnect: (state) => {
      state.isRnsConnectionSuccess = false;
    },
    rnsConnectionSuccess: (state) => {
      state.isRnsConnectionSuccess = true;
      state.isRnsConnectionClosed = false;
    },
    setSipConnectionSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSipConnectionSuccess = action.payload;
    },
    appUnloading: (state) => {
      state.isUnloading = true;
    },
    failedRnsConnection: (state) => {
      state.isRnsConnectionClosed = true;
      state.isRnsConnectionSuccess = false;
    },
  },
});

export const {
  setInnerLoading,
  rnsAttemptingToReconnect,
  rnsConnectionSuccess,
  setSipConnectionSuccess,
  appUnloading,
  failedRnsConnection,
} = appSlice.actions;

export default appSlice.reducer;
