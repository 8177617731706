import type { PopoverOrigin } from '@mui/material';
import { Popover as MuiPopover } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

import type { Popover } from 'features/menu/interfaces';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

const Container = styled('div')`
  width: 240px;
  padding: ${theme.spacing(4)};
  border-radius: ${theme.spacing(2)};
  background-color: ${theme.palette.grey[800]};
`;

interface MenuPopoverOldProps extends PropsWithChildren {
  popover: Popover;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

const defaultAnchorOriginValue: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const defaultTransformOriginValue: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

export const MenuPopoverOld: FC<MenuPopoverOldProps> = ({
  children,
  popover,
  anchorOrigin = defaultAnchorOriginValue,
  transformOrigin = defaultTransformOriginValue,
}) => {
  return (
    <MuiPopover
      id={popover.popoverId}
      open={popover.isOpenPopover}
      anchorEl={popover.anchorEl}
      onClose={popover.handleClosePopover}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <Container>{children}</Container>
    </MuiPopover>
  );
};
