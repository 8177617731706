import { createAsyncThunk } from '@reduxjs/toolkit';

import { setJoinedToZoom, participantCountUpdated } from './conferenceSlice';
import { selectDidVrsAgentGetConnectedToConferenceRoomSelector } from './selectors';

import { ConferenceWindowService } from 'features/call/call-conference/services';
import type { RootState } from 'features/app/store/store';
import {
  ConnectionChangeType,
  InterpretingSessionType,
} from 'features/call/call-base/enums';
import { CallDaoService } from 'features/call/call-base/services';
import {
  callIdSelector,
  terminationTypeSelector,
} from 'features/call/call-base/store/selectors';
import { sessionIdSelector } from 'features/session/store';
import {
  leaveRemoteTeaming,
  isTeamingInProgressSelector,
  leaveRemoteTeamingConference,
} from 'features/teaming/teaming-base/store';
import { isPrimaryVcoSenderSelector } from 'features/vco/store';
import { sendAnalyticsInfo } from 'features/analytics/helpers';
import { isReportNeededSelector } from 'features/call/vrs-call-comments/store';

export const leaveConferenceTeaming = createAsyncThunk(
  'conference-teaming/leave',
  async (payload, { dispatch }) => {
    await dispatch(leaveRemoteTeaming());
    ConferenceWindowService.close();
  }
);

export const disconnectFromConference = createAsyncThunk(
  'conference/disconnect',
  async (
    params: {
      isPrimaryVco?: boolean;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const isTeamingInProgress = isTeamingInProgressSelector(state);
    const isPrimaryVcoSender =
      params.isPrimaryVco ?? isPrimaryVcoSenderSelector(state);
    const participantCount = state.conference.participantCount;

    ConferenceWindowService.closeWithStateCheck();
    if (isTeamingInProgress) {
      await dispatch(leaveRemoteTeamingConference());
    }

    if (isPrimaryVcoSender && !isTeamingInProgress && participantCount >= 3) {
      await dispatch(sendConferenceConnectionState(false));
    }
  }
);

export const getDetailsForConferenceClose = createAsyncThunk(
  'conference/getDetailsForConferenceClose',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const isReportNeeded = isReportNeededSelector(state);
    const isPrimaryVcoSender = isPrimaryVcoSenderSelector(state);
    const isTeamingInProgress = isTeamingInProgressSelector(state);
    const currentTerminationType = terminationTypeSelector(state);
    const didAgentGetConnectedToConferenceRoom =
      selectDidVrsAgentGetConnectedToConferenceRoomSelector(state);
    return {
      isReportNeeded,
      isPrimaryVcoSender,
      isTeamingInProgress,
      currentTerminationType,
      didAgentGetConnectedToConferenceRoom,
    };
  }
);

export const handleTeamingCleanup = createAsyncThunk(
  'conference/handleTeamingCleanup',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const isTeamingInProgress = isTeamingInProgressSelector(state);

    if (isTeamingInProgress) {
      console.log(`DEBUG: Leaving remote teaming`);
      await dispatch(leaveRemoteTeamingConference());
    }
  }
);

export const handleConferenceConnect = createAsyncThunk(
  'conference/handleConnect',
  async (_, { dispatch }) => {
    dispatch(setJoinedToZoom());
    await dispatch(sendConferenceConnectionState(true));
  }
);

export const handleConferenceStateDisconnect = createAsyncThunk(
  'conference/handleStateDisconnect',
  async (_, { dispatch }) => {
    await dispatch(sendConferenceConnectionState(false));
  }
);

const sendConferenceConnectionState = createAsyncThunk(
  'conference/sendConnectionState',
  async (isConnected: boolean, { getState, dispatch }) => {
    const state = getState() as RootState;
    const {
      conference: { didAgentLeaveConference },
    } = state;
    const isPrimaryVcoSender = isPrimaryVcoSenderSelector(state);
    const callId = callIdSelector(state);
    const sessionLoginHistoryId = sessionIdSelector(state);

    if (!isPrimaryVcoSender || !callId) {
      return;
    }

    const connectionStateHistoryParams = {
      interpretingSessionType: InterpretingSessionType.NORMAL,
      isDeafConnected: isConnected,
      isHearingConnected: isConnected,
      callId,
      sessionLoginHistoryId,
    };

    let deafConnectionChangeType = ConnectionChangeType.DEAF_DISCONNECT;
    if (isConnected) {
      deafConnectionChangeType = ConnectionChangeType.DEAF_CONNECT;
    } else if (didAgentLeaveConference) {
      deafConnectionChangeType = ConnectionChangeType.AGENT_DISCONNECT_DEAF;
    }

    // First record the deaf connection state
    await CallDaoService.connectionStateHistory({
      ...connectionStateHistoryParams,
      connectionChangeType: deafConnectionChangeType,
      isHearingConnected: false,
    });

    // Then record the hearing connection state they must be sequential
    await CallDaoService.connectionStateHistory({
      ...connectionStateHistoryParams,
      connectionChangeType: isConnected
        ? ConnectionChangeType.HEARING_CONNECT
        : ConnectionChangeType.HEARING_DISCONNECT,
    });

    dispatch(
      sendAnalyticsInfo({
        event: 'ConferenceConnectionState',
        Method: 'sendConferenceConnectionState',
        Message: `Conference ${isConnected ? 'Connects' : 'Disconnects'} recorded for hearing and deaf participants`,
      })
    );
  }
);

export const handleParticipantCountUpdate = createAsyncThunk(
  'conference/handleParticipantCount',
  async (participantCount: number, { dispatch, getState }) => {
    const state = getState() as RootState;
    const isPrimaryVcoSender = isPrimaryVcoSenderSelector(state);
    const previousCount = state.conference.participantCount;

    dispatch(participantCountUpdated(participantCount));

    if (isPrimaryVcoSender) {
      if (previousCount < 3 && participantCount >= 3) {
        dispatch(setJoinedToZoom());
        await dispatch(sendConferenceConnectionState(true));
      } else if (previousCount >= 3 && participantCount < 3) {
        await dispatch(sendConferenceConnectionState(false));
      }
    }
    dispatch(
      sendParticipantCountChangeThunk({ previousCount, participantCount })
    );
  }
);

export const sendParticipantCountChangeThunk = createAsyncThunk(
  'conference/sendParticipantCount',
  async (
    {
      previousCount,
      participantCount,
    }: { previousCount: number; participantCount: number },
    { dispatch }
  ) => {
    const MAX_PARTICIPANTS_BEFORE_PAUSE = 10;

    if (
      participantCount > MAX_PARTICIPANTS_BEFORE_PAUSE &&
      previousCount <= MAX_PARTICIPANTS_BEFORE_PAUSE
    ) {
      await dispatch(
        sendAnalyticsInfo({
          Method: 'handleParticipantCountUpdate',
          Count: participantCount,
          Message: `Participant count changed from ${previousCount} to ${participantCount}. Updates paused until count drops below ${MAX_PARTICIPANTS_BEFORE_PAUSE}`,
          PreviousCount: previousCount,
          ParticipantCount: participantCount,
          LogGroup: 'Zoom',
        })
      );
      return;
    }

    if (participantCount > MAX_PARTICIPANTS_BEFORE_PAUSE) {
      return;
    }

    await dispatch(
      sendAnalyticsInfo({
        Method: 'handleParticipantCountUpdate',
        Count: participantCount,
        Message: `Participant count changed from ${previousCount} to ${participantCount}`,
        PreviousCount: previousCount,
        ParticipantCount: participantCount,
        LogGroup: 'Zoom',
      })
    );
  }
);
