import type { RTCSession } from 'jssip/lib/RTCSession';
import {
  catchError,
  first,
  firstValueFrom,
  map,
  of,
  timeout,
  TimeoutError,
  filter,
} from 'rxjs';

import type { SipInfoMessage } from 'features/sip/interfaces';
import { SipSessionEventBus } from 'features/sip/services';

/** The Content-Type for SIP INFO messages. */
const DHVI_MESSAGE = 'text/dhvi_message';

export const sendWavelloCheck = function (phoneNumber: string) {
  const message: SipInfoMessage = [
    DHVI_MESSAGE,
    `DHVICapabilityCheck ${phoneNumber}`,
  ];
  SipSessionEventBus.outgoingInfo$.next(message);
};

export const sendWavelloSwitch = function (session: RTCSession) {
  // See Wavello topic "DHVISwitch" and TER-4084
  // to learn why Mercury should probably never send this.
  session.sendInfo(DHVI_MESSAGE, 'DHVISwitch');
};

export const sendWavelloDisconnect = function (session: RTCSession) {
  session.sendInfo(DHVI_MESSAGE, 'DHVIDisconnect');
};

export const nextWavelloIsCapable = async function (): Promise<boolean> {
  const pipe = SipSessionEventBus.incomingInfo$.pipe(
    first(
      (info) =>
        info.contentType === DHVI_MESSAGE && info.body.includes('DHVICapable ')
    ),
    map((info) => {
      const match = info.body.match(/^DHVICapable (\w+)/);
      if (match === null) {
        console.error('Wavello: nTouch did not send a boolean.', {
          message: info.body,
        });
        return false;
      }
      const word = match[1];
      const isWavelloRegistered = word === 'true';

      console.log('Wavello: received DHVICapable response.', {
        match,
        isWavelloRegistered,
      });

      return isWavelloRegistered;
    }),
    timeout(30_000),
    catchError((err) => {
      if (err instanceof TimeoutError) {
        console.error(
          'Wavello: timed out while waiting for DHVICapable. (30s)'
        );
      } else {
        console.error('Wavello: error while waiting for DHVICapable.', { err });
      }
      return of(false);
    })
  );

  const promise = firstValueFrom(pipe, { defaultValue: false });
  return await promise;
};

export const nextWavelloSwitch = function (): Promise<void> {
  console.log('DHVI: Starting to wait for DHVISwitch');

  const switchTimeout = new Promise<void>((_, reject) => {
    setTimeout(
      reject,
      30_000,
      'DHVI: Timed out while waiting for DHVISwitch. (30s).'
    );
  });

  const waitForSwitch = new Promise<void>((resolve) => {
    const subscription = SipSessionEventBus.incomingInfo$
      .pipe(
        filter(
          (info) =>
            info.contentType === DHVI_MESSAGE && info.body === 'DHVISwitch'
        )
      )
      .subscribe(() => {
        console.log('DHVI: Received DHVISwitch message via EventBus');
        subscription.unsubscribe();
        resolve();
      });
  });

  return Promise.race([switchTimeout, waitForSwitch]);
};
